.page-header {
  color: #0052a0;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}
.change-password-page {
  .ant-form-item-label {
    padding: 0 !important;
  }
  .ant-input-password {
    padding: 7px 11px;
  }
  .ant-btn-primary {
    background-color: #69b951;
    border-radius: 20px;
    &:hover {
      background-color: #74c95a;
    }
    &:active {
      background-color: #60a74b;
    }
  }
  .ant-btn {
    border-radius: 20px;
  }
  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}
