.home-main {
  background-color: #2369cc;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .home-top-section {
    padding: 1px 15px 20px 15px;
    height: 121px;
    .user-info {
      margin-top: 15px;
      color: white;
      display: flex;
      justify-content: space-between;
      .user-name {
        width: calc(100vw - 85px);
        font-weight: 600;
        font-size: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .user-address {
        width: calc(100vw - 85px);
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .user-avatar {
        img {
          border-radius: 50%;
          width: 42px;
          height: 42px;
          object-fit: cover;
        }
      }
    }
    .action-btns {
      display: flex;
      width: 100%;
      gap: 10px;
      margin-top: 10px;
      &-home {
        padding-bottom: 5px;
      }
      .credit {
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        background-color: #ff8b6d;
        color: white;
        padding: 2.5px 5px;
      }
      .batches {
        flex: 2;
        .ant-select {
          border-radius: 3px;
          width: 100%;
        }
      }
    }
  }
  .current-date {
    margin-top: 20px;
    border-radius: 30px;
    background-color: #dbe4ff;
    padding: 6px;
    width: 100%;
    text-align: center;
    color: #0052a0;
    font-size: 20px;
    font-weight: 600;
    z-index: 1000;
    position: relative;
    box-shadow: #00000029 0 3px 6px;
  }
  .home-content {
    background-color: white;
    height: 100%;
    margin-top: -18px;
    padding: 38px 20px 10px 20px;
  }
  .home-footer {
    height: 75px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: white;
    font-size: 10px;
    .footer-btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.side-bar {
  .ant-drawer-header {
    padding-left: 5px !important;
    padding-bottom: 0px !important;
    .ant-drawer-close {
      svg {
        fill: white;
      }
    }
  }
  .ant-drawer-body {
    padding-top: 0px !important;
    .user-avatar {
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        border: 5px solid white;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        object-fit: cover;
      }
    }
    .user-details {
      margin-top: 30px;
      .row-1 {
        display: flex;
        margin: 5px 0;
        .key {
          flex: 0.8;
          color: #eaeef6;
        }
        .value {
          flex: 1;
        }
      }
    }
    .side-bar-links {
      a {
        color: white;
        font-weight: 500;
      }
      .link-item {
        display: flex;
        align-items: center;
        gap: 15px;
        img {
          width: 18px;
        }
      }
    }
  }

  .logout-container {
    position: absolute;
    bottom: 0;
    padding: 20px 0;
    width: calc(100% - 50px);
    display: flex;
    justify-content: space-between;
    .logout-btn {
      background-color: #e35959;
      padding: 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .version-text {
      align-self: flex-end;
    }
  }
}
