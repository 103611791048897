.category-items {
  &-scroll-area {
    height: calc(100vh - 384px);
    overflow-y: scroll;
  }
}
.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
