.caterories-page {
  .scroll-area {
    height: calc(100vh - 382px);
    overflow-y: scroll;
  }
  .categories-card {
    padding: 5px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      border-radius: 3px;
      height: 70px;
      width: 70px;
      overflow: hidden;
    }
    .category-info {
      margin-top: 5px;
      font-size: 12px;
      font-weight: 600;
    }
  }
}
.grid-view {
  display: grid;
  row-gap: 10px;
  column-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
}
