.cart-items {
  &-scroll-area {
    height: calc(100vh - 344px);
    overflow-y: scroll;
  }
  .price-breakdown {
    margin-top: 20px;
    .ant-divider {
      margin: 10px 0;
    }
  }
  .net-amount {
    .flex-row {
      font-size: 18px;
      font-weight: 600;
    }
  }
}
.flex-row {
  display: flex;
  justify-content: space-between;
}
.manual-payment-form {
  .ant-form-item-row {
    justify-content: center;
    .ant-form-item-control-input {
      justify-content: center;
      .ant-form-item-control-input-content {
        max-width: 215px;
        input {
          height: 40px;
        }
      }
    }
    .ant-form-item-label {
      label {
        width: 100%;
        display: "flex";
        justify-content: center;
      }
    }
  }
  .ant-form-item-explain-error {
    text-align: center;
    margin-bottom: 10px;
  }
}

.ant-table-thead {
  tr {
    th {
      padding: 7px 5px !important;
    }
  }
}
.ant-table-header {
  border-radius: 0px !important;
}
.ant-table-title {
  padding: 10px !important;
  border: 1px solid black !important;
  border-bottom: none !important;
}
.ant-table-cell {
  border: 0.5px solid black !important;
}
.ant-table-cell:nth-child(odd) {
  border-right: none !important;
}
.ant-table-tbody {
  tr {
    td {
      padding: 3px !important;
    }
  }
}
.ant-table-pagination-right {
  justify-content: center !important;
  max-width: 100vw !important;
  max-height: 32px !important;
  overflow: hidden !important;
  margin: 16px 0 0 0 !important;
  .ant-pagination-item {
    margin-inline-end: 0px !important;
  }
}
