.product-detail {
  .product-img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .img-background {
      width: 190px;
      height: 190px;
      box-shadow: #00000021 0px 3px 5px;
      img {
        width: 188px;
        height: 188px;
      }
    }
  }
  .product-name,
  .product-price {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
  .product-name {
    padding-top: 20px;
  }
  .product-price {
    padding-top: 7px;
    padding-bottom: 20px;
    &-mrp {
      font-size: 16px;
      font-weight: normal;
      text-decoration: line-through;
    }
  }
  .product-desc {
    text-align: justify;
    height: calc(100vh - 642px);
    overflow-y: scroll;
    .ant-divider-inner-text {
      font-size: 18px;
      font-weight: 600;
    }
    .flex-row {
      justify-content: flex-start;
      gap: 10px;
      .key {
        font-weight: 500;
      }
    }
  }
  .back-btn {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .close-btn {
    float: right;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    border-radius: 50%;
    border: 2px solid #989696;
    font-family: cursive;
    color: #989696;
  }
}
