.reports-main {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 10px;
  height: calc(100vh - 315px);
  overflow-y: scroll;
  margin-top: 10px;
  .report-menu-item {
    flex: 1;
  }
  // .card {
  //   flex-basis: 48%;
  // }
}
.invoice-form {
  input {
    height: 40px;
  }
  .ant-picker {
    width: 100% !important;
    .ant-picker-input {
      height: 30px !important;
    }
  }
}
.invoice-message {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #deffd5;
  padding: 0 10px 20px 10px;
  border-radius: 7px;
  box-shadow: 1px 1px 7px 1px #d0d0d08a;
}
.date-range-form {
  display: flex;
  align-items: center;
  gap: 5px;
}
