.start-indent {
  text-align: center;
}
.notifications {
  margin-top: 20px;
  .notification-header {
    color: #0052a0;
    font-size: 20px;
    font-weight: 600;
    .clear-all {
      font-size: 12px;
      background-color: #dbe4ff;
      display: flex;
      align-items: center;
      padding: 0px 7px;
      border-radius: 20px;
    }
  }
  &-scroll-area {
    height: calc(100vh - 419px);
    overflow-y: scroll;
  }
  &-all-scroll-area {
    height: calc(100vh - 321px);
    overflow-y: scroll;
  }
}
.error-container {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #fce3e3;
  color: #c80000;
  font-weight: 500;
  border-radius: 6px;
  padding: 10px 5px;
}
