.row {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;

  .key,
  .value {
    flex: 1;
    word-wrap: break-word;
  }
  .value {
    text-align: right;
  }
}
