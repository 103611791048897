.login-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  .top-section {
    flex: 1.6;
    background-image: linear-gradient(
      180deg,
      hsl(215, 71%, 47%) 0%,
      hsl(215, 73%, 94%) 43%,
      hsl(216, 71%, 82%) 51%,
      hsl(215, 71%, 70%) 62%,
      hsl(215, 71%, 59%) 91%,
      hsl(215, 71%, 47%) 100%
    );
    border-radius: 0 0 20px 20px;
    color: white;
    .login-top-logo {
      margin-top: 6%;
      display: flex;
      justify-content: center;
    }
    .tag-line {
      p {
        font-weight: 500;
        text-align: center;
        margin: 0;
      }
    }
  }
  .middle-section {
    flex: 3;
    margin-top: 49px;
    padding: 0 20px;
    .login-form-header {
      text-align: center;
      color: #1d519b;
      font-weight: 600;
      font-size: 20px;
    }
    .login-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 13px;
      .login-form-item {
        width: 100%;
        margin-bottom: 20px;
        .login-input-label {
          color: #6c7b9c;
        }
        .login-input {
          margin-top: 5px;
          width: 100%;
          padding: 10px 5px;
          border-radius: 5px;
          border: 1px solid #d7dde9;
          box-shadow: #6c7b9c3d 0px 1px 2px;
        }
      }
      .forgot {
        width: 100%;
        font-size: 12px;
        color: #ff592d;
        text-align: right;
        margin-bottom: 28px;
      }
    }
  }
  .bottom-section {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
    color: white;
    font-size: 10px;
    width: 100%;
    background: url("../../../assets/images/loginBottom.png") bottom no-repeat;
    background-size: contain;
  }
}
.primary-btn {
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
  width: 215px;
  color: white;
  background-color: #69b951;
  border: 1px solid #449b3e;
  box-shadow: #69b95175 0px 6px 12px;

  &:hover {
    background-color: #449b3e;
  }
}
.secondary-btn {
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
  width: 215px;
  color: white;
  background-color: #afb6c4;
  border: 1px solid #a0a6b3;
  box-shadow: #dddddd 0px 6px 12px;

  &:hover {
    background-color: #a0a6b3;
  }
}
