.card {
  margin-top: 10px;
  background-color: #f3f5fb;
  padding: 10px;
  box-shadow: #00000021 0px 3px 5px;
  color: #818181;
  margin-bottom: 5px;
  border: 1px solid #cfcfcf;
  border-radius: 6px;
}
.ant-select-disabled {
  .ant-select-selector {
    color: rgba(0, 0, 0, 0.5) !important;
  }
}
