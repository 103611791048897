.action-bar {
  display: flex;
  align-items: center;
  .layout-changer {
    margin-left: auto;
    display: flex;
    gap: 3px;
  }
}
.indent-list {
  &-scroll-area {
    height: calc(100vh - 349px);
    overflow-y: scroll;
  }
  .card {
    color: black;
    display: flex;
    gap: 10px;
    font-size: 14px;
    padding: 10px 10px 5px 10px;
    .product-info {
      // font-size: 12px;
      width: 100%;
      .product-name {
        padding-bottom: 5px;
      }
      .inputs-section {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        .ant-select {
          flex: 1;
          .ant-select-selector {
            height: 30px !important;
            font-size: 12px !important;
            display: flex !important;
            align-items: center !important;
            &:active,
            &:hover {
              border: 1px solid #d9d9d9;
              box-shadow: none;
            }
          }
        }
        .quantity-selector {
          flex: 1;
          height: 30px;
          display: flex;
          width: 100px;
          background-color: white;
          border: 1px solid #d9d9d9;
          border-radius: 6px;
          text-align: center;
          align-items: center;
          color: #2369cc;
          .decrease,
          .increase {
            flex: 0.5;
            font-size: 18px;
            font-weight: 500;
          }
          .quantity {
            flex: 1;
            padding: 0 3px;
            background-color: #2369cc;
            color: white;
            .ant-input-number {
              background-color: inherit;
              color: inherit;
              width: inherit;
              border: none;
              .ant-input-number-input-wrap {
                .ant-input-number-input {
                  text-align: center;
                  color: inherit;
                  padding: 0px;
                  height: auto;
                }
              }
            }
          }
        }
      }
    }
    .bottom-row {
      display: flex;
      justify-content: space-between;
      margin-top: 2px;
      font-weight: 600;
    }
    .price,
    .product-name {
      font-weight: 600;
    }
    img {
      border-radius: 3px;
      height: 70px;
      width: 70px;
      overflow: hidden;
    }
  }
}
.continue-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  gap: 15px;
}
.bottom-modal {
  margin: 0 !important;
  position: absolute !important;
  bottom: 0 !important;
  top: unset !important;
  padding: 0 !important;
  .ant-modal-content {
    width: 100vw;
    border-radius: 10px 10px 0 0 !important;
    .header-img-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    p {
      font-weight: 600;
      text-align: center;
      font-size: 16px;
    }
  }
}
.action-btns {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  padding-bottom: 10px;
}
